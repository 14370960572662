body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  --defaultMainColor: #2c458b
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #99a9d4;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: var(--defaultMainColor);
  border-radius: 2px;
}

/* Set the width of the scrollbar */
::-webkit-scrollbar {
  width: 3px;
  height: 7px;
}

/* antd classes */
.ant-checkbox .ant-checkbox-inner {
  height: 20px !important;
  width: 20px !important;
}

.ant-form-item .ant-form-item-explain-error {
  text-align: left;
  margin-top: 1px;
}

aside.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-has-trigger.toggling_sider {
  min-width: 243px !important;
}

.ant-menu-light .ant-menu-item-selected {
  color: #000;
  font-weight: 600;
  background-color: #f8f9fe;
  border-left: 5px solid var(--defaultMainColor);
  border-radius: 0px;
  margin-inline: 0px !important;
}

.ant-menu-inline .ant-menu-item {
  height: 50px !important;
}

.ant-switch.ant-switch-checked {
  background-color: #29b5a9 !important;
}

.ant-switch {
  min-width: 40px !important;
  height: 17px !important;
}

.ant-switch .ant-switch-handle {
  width: 15px !important;
  height: 15px !important;
  top: 1px !important;
}

.ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 17px);
}

.ant-pagination .ant-pagination-item-active {
  background-color: var(--defaultMainColor);
  border-radius: 50%;
  color: #fff;
  border-color: var(--defaultMainColor);
  font-size: 1rem;
}

.ant-pagination .ant-pagination-item {
  min-width: 33px !important;
  height: 33px !important;
}

.modal_form .ant-form-item {
  margin-bottom: -4px !important
}

.ant-select-single {
  height: 45px !important;
}

.car_dropDwn .ant-select-single {
  height: 35px !important;
  width: 157px;
  text-align: start;
}

.antd_upload .ant-tooltip {
  display: none !important;
}

.ant-table-wrapper .ant-table-thead>tr>th {
  font-size: .8rem;
  color: #8898aa;
  background-color: #f6f9fc;
}

.ant-layout .ant-layout-sider-light .ant-layout-sider-trigger {
  display: none;
}

span.anticon.anticon-eye {
  display: none;
}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item-error{
  border-color: #d9d9d9 !important;
}