* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  --defaultMainColor: #2c458b
}

.loginLeftSideDiv {
  background-image: url('./assets/login_bg.svg');
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  height: 100vh;
  background-color: #2c458b;
}

.car_logo {
  width: 22%;
}

.login_formDiv {
  width: 60%;
  margin-inline: auto;
}

.login_btn {
  color: #fff;
  border-color: #2c458b;
  background-color: #2c458b;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  font-size: 0.9rem;
  font-weight: bold;
  height: 46px;

}

.input_box {
  box-shadow: 0 1px 3px rgba(50, 50, 93, .15), 0 1px 0 rgba(0, 0, 0, .02);
  height: 45px;
  background-color: #fff;
}

.header {
  background-color: #fff;
  box-shadow: 0 5px 15px #b3aaab0d;
  color: #000;
  justify-content: space-between;
  opacity: 1;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

.header_logo {
  width: 6%;
}

.arc_adminText {
  min-width: 243px;
}

.content_style {
  text-align: center;
  min-height: 100vh;
  background-color: #f8f9fe;
  max-height: 100vh;
  overflow-y: auto;
  padding: 40px;
  /* margin-left: 243px; */
}

.dashboard_card {
  border: 1px solid var(--defaultMainColor);
  box-shadow: 0px 12px 5px 0px rgb(0 0 0 / 10%);
  border-radius: 13px;
  background-color: #fff;
  min-height: 130px;
}

.text_color {
  color: var(--defaultMainColor);
  font-size: 1rem;
}

.table_add_button {
  background-color: var(--defaultMainColor);
  color: white;
  padding: 4px 8px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.modal_button {
  color: #fff;
  height: 35px;
  font-size: 1rem;
}

.logo_laoding {
  height: 300px;
}

.lazy_loading {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.web_link {
  color: #000;
  text-decoration: none;
}

.web_link:hover {
  text-decoration: underline;
}

.csv_button {
  color: #212529;
  border-color: #f7fafc;
  background-color: #f7fafc;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.leftSideDiv {
  border-right: 1px solid grey;
  border-bottom: 1px solid grey;
  /* height: 40px; */
  height: 100%;
  /* width: 200; */
}

.rightSideDiv {
  border-left: 1px solid grey;
  border-bottom: 1px solid grey;
  height: 100%;
  /* width: 200; */
}


/* *************flight info table classes**************** */
.dept_arrival {
  background: #ffd602;
  padding: 0.2rem 5rem 0.5rem 1rem;
  border-radius: 0 50px 0 0;
  font: 600 1.1rem "Roboto", sans-serif;
  min-width: 200px;
  width: fit-content;
}
.td {
  background-color: #d9eafa;
  padding: 5px 10px;
  text-align: end;
}
.td:nth-child(even) {
  background-color: #ecf5fc;
}

.sideHeading {
  text-align: start;
  color: #1a1919;
  font-weight: bold;
  font-size: 0.9rem;
}
.sideValue {
  text-align: start;
  color: #504f4f;
  font-weight: bold;
  font-size: 0.9rem;
}

.ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-error{
  border:none !important
}